// ** Icons Import
import { DollarSign, Home, Radio, User, Shield, Settings, Users, Lock } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <Home />,
    action: 'read',
    resource: 'dashboards',
    navLink: '/dashboard/admin'
  },
  {
    id: 'organizations',
    title: 'Organizations',
    icon: <Shield />,
    action: 'read',
    resource: 'org-organizations',
    navLink: '/orgs/organizations/list'
  },  
  {
    id: 'leagues',
    title: 'Leagues',
    icon: <Shield />,
    action: 'read',
    resource: 'org-leagues',
    navLink: '/orgs/leagues/list'
  },
  {
    id: 'teams',
    title: 'Teams',
    icon: <Shield />,
    action: 'read',
    resource: 'org-teams',
    navLink: '/orgs/teams/list'
  },
  {
    id: 'divisions',
    title: 'Divisions',
    icon: <Shield />,
    action: 'read',
    resource: 'org-divisions',
    navLink: '/orgs/divisions/list'
  },
  {
    id: 'campaigns',
    title: 'Campaigns',
    icon: <Radio />,
    action: 'read',
    resource: 'campaigns',
    navLink: '/campaigns/list'
  },
  {
    id: 'fundraisers',
    title: 'Players',
    icon: <User />,
    action: 'read',
    resource: 'fundraisers',
    navLink: '/fundraisers/list'
  },
  {
    id: 'donations',
    title: 'Donations',
    icon: <DollarSign />,
    action: 'read',
    resource: 'donations',
    navLink: '/donations/list'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: <Settings />,
    children: [
      {
        id: 'users',
        title: 'User',
        icon: <Lock />,
        navLink: '/apps/user/list'
      },
      {
        id: 'affiliates',
        title: 'Affiliates',
        icon: <Users />,
        navLink: '/affiliates/list'
      },
      {
        id: 'onboardings',
        title: 'Registrations',
        icon: <Users />,
        navLink: '/onboarding/list'
      }
      // ,
      // {
      //   id: 'todo',
      //   title: 'Onboarding Tasks',
      //   icon: <CheckSquare />,
      //   navLink: '/apps/todo'
      // }
    ]
  }
]
