// ** React Imports
import { lazy } from 'react'

import { Navigate } from 'react-router-dom'

const OrganizationList = lazy(() => import('../../views/orgs/organizations/list'))
const OrganizationView = lazy(() => import('../../views/orgs/organizations/view'))

const LeagueList = lazy(() => import('../../views/orgs/leagues/list'))
const LeagueView = lazy(() => import('../../views/orgs/leagues/view'))

const TeamSearch = lazy(() => import('../../views/orgs/teams/search'))
const TeamList = lazy(() => import('../../views/orgs/teams/list'))
const TeamView = lazy(() => import('../../views/orgs/teams/view'))

const DivisionList = lazy(() => import('../../views/orgs/divisions/list'))
const DivisionView = lazy(() => import('../../views/orgs/divisions/view'))

const RegisterTeamMultiSteps = lazy(() => import('../../views/orgs/teams/list/register-team-multi-steps'))

const OrgRoutes = [
  

  {
    element: <OrganizationList />,
    path: '/orgs/organizations/list',
    meta: {
      action: 'read',
      resource: 'org-organizations'
    }
  },
  {
    path: '/orgs/organizations/view',
    element: <Navigate to='/orgs/organizations/view/1' />,
    meta: {
      action: 'read',
      resource: 'org-organizations'
    }
  },
  {
    element: <OrganizationView />,
    path: '/orgs/organizations/view/:id',
    meta: {
      action: 'read',
      resource: 'org-organizations'
    }
  },

  {
    element: <LeagueList />,
    path: '/orgs/leagues/list',
    meta: {
      action: 'read',
      resource: 'org-leagues'
    }
  },
  {
    path: '/orgs/leagues/view',
    element: <Navigate to='/orgs/leagues/view/1' />,
    meta: {
      action: 'read',
      resource: 'org-leagues'
    }
  },
  {
    element: <LeagueView />,
    path: '/orgs/leagues/view/:id',
    meta: {
      action: 'read',
      resource: 'org-leagues'
    }
  },

  {
    element: <TeamSearch />,
    path: '/orgs/teams/search',
    meta: {
      action: 'read',
      resource: 'org-teams'
    }
  },
  {
    element: <TeamList />,
    path: '/orgs/teams/list',
    meta: {
      action: 'read',
      resource: 'org-teams'
    }
  },
  {
    path: '/orgs/teams/view',
    element: <Navigate to='/orgs/teams/view/1' />,
    meta: {
      action: 'read',
      resource: 'org-teams'
    }
  },
  {
    element: <TeamView />,
    path: '/orgs/teams/view/:id',
    meta: {
      action: 'read',
      resource: 'org-teams'
    }
  },

  {
    element: <DivisionList />,
    path: '/orgs/divisions/list',
    meta: {
      action: 'read',
      resource: 'org-divisions'
    }
  },
  {
    path: '/orgs/divisions/view',
    element: <Navigate to='/orgs/divisions/view/1' />,
    meta: {
      action: 'read',
      resource: 'org-divisions'
    }
  },
  {
    element: <DivisionView />,
    path: '/orgs/Divisions/view/:id',
    meta: {
      action: 'read',
      resource: 'org-divisions'
    }
  },
  {
    element: <RegisterTeamMultiSteps />,
    path: '/orgs/teams/add-team',
    meta: {
      action: 'read',
      resource: 'org-teams'
    }
  }
]

export default OrgRoutes
