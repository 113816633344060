// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appTeams/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_CLUB}`)
  return response.data
})

export const getData = createAsyncThunk('appTeams/getData', async params => {

  const { q, searchSales } = params
    localStorage.setItem('teamSearch', JSON.stringify(params))
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_CLUB}&q=${q}&sales=${searchSales}`, params)
    return {
      params,
      data: response.data.data,
      totalPages: response.data
    }
  // } else {
  //   return {
  //     params,
  //     data: [],
  //     totalPages: 0
  //   }
  // }
})

export const getMyTeams = createAsyncThunk('appTeams/getMyTeams', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user_organization/`)
  return response.data
})

export const createPayment = createAsyncThunk('appTeams/createPayment', async (paymentParams) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/${paymentParams.stripeAccount}/payout`, paymentParams)
})

export const releaseEscrowFunds = createAsyncThunk('appTeams/releaseEscrowFunds', async (id) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/processTransfers`, {
    isEscrow: false,
    account: id
  })
})

export const uploadProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/avatar`, formData)
}

export const uploadSponsorshipImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/sponsopship_opportunity/${id}/image`, formData)
}

export const getTeam = createAsyncThunk('appTeams/getTeam', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/?organization=${id}`)
  return response.data.data
})

export const importRoster = createAsyncThunk('appTeams/importRoster', async (playerObject, { dispatch }) => {
  const { id, ...player_data } = playerObject
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/team/${id}/roster_import`, player_data)
  await dispatch(getTeam(id))
  return response?.data?.data
})

export const importTeamRoster = createAsyncThunk('appTeams/importTeamRoster', async (playerObject, { dispatch }) => {
  const { id, ...player_data } = playerObject
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/team/${id}/division_import`, player_data)
  await dispatch(getTeam(id))
  return response?.data?.data
})

export const validateCustomUrl = (slug, entityType = 'organization') => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/${entityType}/validate-url/${slug}?showDetail=0`)
}

export const getTeamDivisions = createAsyncThunk('appTeams/getTeamDivisions', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/filter?parent=${id}`)
  return response.data
})

export const addTeamDivision = createAsyncThunk('appTeams/addTeamDivision', async (teamDivision, { dispatch }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/division/create`, teamDivision)
  await dispatch(getTeam(teamDivision.parent))
  return teamDivision
})

export const getTeamUsers = createAsyncThunk('appTeams/getTeamUsers', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/users`)
  return response.data
})

export const addCampaign = createAsyncThunk('appLeagues/addCampaign', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getTeam(campaign.organization))
  return response?.data
})

export const editCampaign = createAsyncThunk('appLeagues/editCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return response?.data
})

export const addTeamUser = createAsyncThunk('appTeams/addTeamUser', async (teamUser, { dispatch }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/${teamUser.organizationId}/user`, teamUser)
  await dispatch(getTeamUsers(teamUser.organizationId))
  return teamUser
})

export const resendInvite = createAsyncThunk('appTeams/resendInvite', async (userData) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/resendInvite`, userData)
  return response?.data
})

export const getTeamCampaigns = createAsyncThunk('appTeams/getTeamCampaigns', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/filter?organization=${id}`)
  return response.data
})

export const addTeam = createAsyncThunk('appTeams/addTeam', async (team, { dispatch, getState }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, team)
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return team
})

export const updateTeam = createAsyncThunk("appLeagues/updateTeam", async (team, { dispatch }) => {
  await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/organization`, team)
  await dispatch(getTeam(team.id))
  return response.data.data
})

export const deleteTeam = createAsyncThunk('appTeams/deleteTeam', async (id, { dispatch, getState }) => {
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/teams/delete`, { id })
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return id
})

export const appTeamsSlice = createSlice({
  name: 'appTeams',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    myTeams: null,
    teamDivisions:[],
    teamCampaigns:[],
    teamUsers:[],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getMyTeams.fulfilled, (state, action) => {
        state.myTeams = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTeam.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getTeamDivisions.fulfilled, (state, action) => {
        state.selectedUser.children = action.payload.data
      })
      .addCase(getTeamCampaigns.fulfilled, (state, action) => {
        state.teamCampaigns = action.payload.data
      })
      .addCase(getTeamUsers.fulfilled, (state, action) => {
        state.teamUsers = action.payload.data
      })
  }
})

export default appTeamsSlice.reducer
