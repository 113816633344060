// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appOrganizations/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_ORG}`)
  return response.data
})

export const getData = createAsyncThunk('appOrganizations/getData', async params => {
  // const response = await axios.get('/api/teams/list/data', params)
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_ORG}`, params)
  // const teams = {
  //   teams: response.data
  // }
  return {
    params,
    data: response.data.data,
    totalPages: response.data
    //totalPages: 1
  }
})

export const uploadImage = (formData) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/upload-avatar`, formData)
}

export const getOrganization = createAsyncThunk('appOrganizations/getOrganization', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/?organization=${id}`)
  return response.data.data
})

export const updateOrganization = createAsyncThunk("appOrganizations/updateOrganization", async (organization, { dispatch }) => {
    await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/organization`, organization)
    await dispatch(getOrganization(organization.id))
    return response.data.data
})

export const addOrganization = createAsyncThunk('appOrganizations/addOrganization', async (organization, { dispatch, getState }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, organization)
  await dispatch(getData(getState().organizations.params))
  // await dispatch(getAllData())
  return organization
})

export const deleteOrganization = createAsyncThunk('appOrganizations/deleteOrganization', async (id, { dispatch, getState }) => {
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organizations/delete`, { id })
  await dispatch(getData(getState().organizations.params))
  await dispatch(getAllData())
  return id
})

export const uploadProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/avatar`, formData)
}

export const getUsers = createAsyncThunk('appOrganizations/getUsers', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/users`)
  return response.data?.data
})

export const addUser = createAsyncThunk('appOrganizations/addUser', async (userData, { dispatch }) => {
  const { parentOrgId, ...user } = userData
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/${parentOrgId}/user`, user)
  //const newusers = await dispatch(getUsers(parentOrgId))
  await dispatch(getOrganization(parentOrgId))
  return response
})

export const addLeague = createAsyncThunk('appOrganizations/addLeague', async (leagueData, { dispatch }) => {
  const { ...league } = leagueData
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, league)
  await dispatch(getOrganization(league.parent))
  return leagueData
})

export const addCampaign = createAsyncThunk('appOrganizations/addCampaign', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getOrganization(campaign.organization))
  return response?.data
})

export const resendInvite = createAsyncThunk('appOrganizations/resendInvite', async (userData) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/resendInvite`, userData)
  return response?.data
})

export const validateCustomUrl = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/validate-url/${slug}?showDetail=0`)
}

export const editCampaign = createAsyncThunk('appOrganizations/editCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getOrganization(campaign.organization))
  return response?.data
})

export const deleteOrgUser = createAsyncThunk('appOrganizations/editCadeleteOrgUsermpaign', async (userorg, { dispatch }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/${userorg.id}`)
  await dispatch(getOrganization(userorg.organization))
  return response?.data
})

export const appOrganizationsSlice = createSlice({
  name: 'appOrganizations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.data.length
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const newUser = state.selectedUser
        delete newUser.users
        newUser.users = action.payload
        state.selectedUser = newUser
      })
  }
})

export default appOrganizationsSlice.reducer
