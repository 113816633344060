// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appLeagues/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_LEAGUE}`)
  return response.data
})

export const getData = createAsyncThunk('appLeagues/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_LEAGUE}`, params)
  return {
    params,
    data: response.data.data,
    totalPages: response.data
  }
})

export const createPayment = createAsyncThunk('appLeagues/createPayment', async (paymentParams) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/${paymentParams.stripeAccount}/payout`, paymentParams)
})

export const releaseEscrowFunds = createAsyncThunk('appLeagues/releaseEscrowFunds', async (id) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/processTransfers`, {
    isEscrow: false,
    account: id
  })
})

export const getLeague = createAsyncThunk('appLeagues/getLeague', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/?organization=${id}`)
  return response.data.data
})

export const updateLeague = createAsyncThunk("appLeagues/updateLeague", async (league, { dispatch }) => {
    await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/organization`, league)
    await dispatch(getLeague(league.id))
    return response.data.data
})

export const addLeague = createAsyncThunk('appLeagues/addLeague', async (league, { dispatch, getState }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, league)
  await dispatch(getData(getState().leagues.params))
  // await dispatch(getAllData())
  return league
})

export const deleteLeague = createAsyncThunk('appLeagues/deleteLeague', async (id, { dispatch, getState }) => {
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organizations/delete`, { id })
  await dispatch(getData(getState().leagues.params))
  await dispatch(getAllData())
  return id
})

export const uploadLeagueProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/avatar`, formData)
}

export const uploadProfileImage = createAsyncThunk('appLeagues/uploadLeagueProfileImage', async (formData, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${getState().selectedUser.id}/avatar`, formData)
  await dispatch(getLeague(getState().selectedUser.id))
  return response.data
})

export const getUsers = createAsyncThunk('appLeagues/getUsers', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/users`)
  return response.data?.data
})

export const resendInvite = createAsyncThunk('appLeagues/resendInvite', async (userData) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/resendInvite`, userData)
  return response?.data
})

export const addUser = createAsyncThunk('appLeagues/addUser', async (userData, { dispatch }) => {
  const { parentOrgId, ...user } = userData
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/${parentOrgId}/user`, user)
  //const newusers = await dispatch(getUsers(parentOrgId))
  await dispatch(getLeague(parentOrgId))
  return response
})

export const validateCustomUrl = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/validate-url/${slug}?showDetail=0`)
}

export const addTeam = createAsyncThunk('appLeagues/addTeam', async (teamData, { dispatch }) => {
  const { ...team } = teamData
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, team)
  await dispatch(getLeague(team.parent))
  return teamData
})

export const addCampaign = createAsyncThunk('appLeagues/addCampaign', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getLeague(campaign.organization))
  return response?.data
})

export const editCampaign = createAsyncThunk('appLeagues/editCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getLeague(campaign.organization))
  return response?.data
})

export const deleteOrgUser = createAsyncThunk('appLeagues/editCadeleteOrgUsermpaign', async (userorg, { dispatch }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/${userorg.id}`)
  await dispatch(getLeague(userorg.organization))
  return response?.data
})

export const appLeaguesSlice = createSlice({
  name: 'appLeagues',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.data.length
      })
      .addCase(getLeague.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const newUser = state.selectedUser
        delete newUser.users
        newUser.users = action.payload
        state.selectedUser = newUser
      })
  }
})

export default appLeaguesSlice.reducer
