// ** React Imports
import { lazy } from 'react'

const Login = lazy(() => import('../../views/pages/authentication/Login'))
const ForgotPasswordBasic = lazy(() => import('../../views/pages/authentication/ForgotPasswordBasic'))
const VerifyEmailBasic = lazy(() => import('../../views/pages/authentication/VerifyEmailBasic'))
const AutoLogin = lazy(() => import('../../views/pages/authentication/AutoLogin'))
const Register = lazy(() => import('../../views/pages/authentication/Register'))
const RegisterMultiSteps = lazy(() => import('../../views/pages/authentication/register-multi-steps'))
const PlayerRegistration = lazy(() => import('../../views/pages/authentication/player-registration'))
const TeamRegistration = lazy(() => import('../../views/pages/authentication/team-registration'))
const RegisterBasic = lazy(() => import('../../views/pages/authentication/RegisterBasic'))
const ActivateAffiliate = lazy(() => import('../../views/pages/affiliate/AcceptInvitation'))
const CampaignRegister = lazy(() => import('../../views/pages/authentication/CampaignRegister'))
const ResetPasswordCover = lazy(() => import('../../views/pages/authentication/ResetPasswordCover'))
const ActivateAccount = lazy(() => import('../../views/pages/authentication/ActivateAccount'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordBasic />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/email-confirm',
    element: <VerifyEmailBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/activate-account',
    element: <ActivateAccount />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/otl',
    element: <AutoLogin />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/register',
    element: <Register />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/affiliate/activate/:affiliate',
    element: <ActivateAffiliate />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/register/:organization/player',
    element: <RegisterBasic />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/campaign/accept-invite/',
    element: <CampaignRegister />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/campaign/accept-invite/:cfid',
    element: <CampaignRegister />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/register/:organization/player/:fundraiser',
    element: <RegisterBasic />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/player',
    element: <PlayerRegistration />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/player/:campaignfundraiserid',
    element: <PlayerRegistration />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/team',
    element: <TeamRegistration />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/onboarding/team/:teamId',
    element: <TeamRegistration />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/pages/register-multi-steps',
    element: <RegisterMultiSteps />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/reset-password',
    element: <ResetPasswordCover />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }
]

export default AuthenticationRoutes
