// ** Icons Import
import { Box, DollarSign } from 'react-feather'

export default [
  {
    id: 'donations',
    title: 'Donations',
    icon: <DollarSign />,
    action: 'read',
    resource: 'donations',
    navLink: '/donations/list'
  }
]
