// ** Navigation imports
import dashboards from './dashboards'
import orgs from './organizations'
import fundraisers from './fundraisers'
import campaigns from './campaigns'
import donations from './donations'
// import reports from './reports'
import settings from './settings'

import system from './system'
import team from './team'
import sales from './sales'
import fundraiser from './fundraiser'
import affiliate from './affiliate'

let exportNav = []
const userRole = (localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).role : "")

if (userRole === "Affiliate") {
  exportNav = [...affiliate]
} else if (userRole === "Fundraiser") {
  exportNav = [...fundraiser]
} else if (userRole === "Super Admin") {
  exportNav = [...system]
} else if (userRole === "Team Admin") {
  exportNav = [...team]
} else if (userRole === "FYS Sales") {
  exportNav = [...sales]
} else {
  exportNav = [...dashboards, ...orgs, ...campaigns, ...fundraisers, ...donations, ...settings]
} 

// ** Merge & Export
export default exportNav
