import './public-pages.scss'

import {
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  Row
} from 'reactstrap'

import { ArrowRightCircle } from 'react-feather'

const NavBar = ({ isAuthorized = false }) => {
  return (
    <div id="Navbar" style={{borderBottom: "solid 1px rgb(200, 200, 200)"}}>
        <Container fluid="md" className='pe-0' style={{maxWidth: "1100px"}}>
          <Row className='navbar-styling'>
            <Col md="4" xs="12" className='myFlex'>
              <NavLink href='https://fundyouthsports.com/' className="d-flex align-items-center">
                <img src={require('@src/assets/images/public_pages/logo.png').default} className='logo ms-1'></img>
              </NavLink>
            </Col>
            <Col md="8" xs="4" className='myFlex d-none d-md-flex align-items-end justify-content-end'>
              <Navbar className='Navbar'
                color="light"
                container="md"
                expand="md"
                light
              >
                <NavbarToggler onClick={() => {}} />
                <Collapse navbar>
                  <Nav
                    className="me-auto"
                    navbar
                  >
                    <NavItem className='NavItem'>
                      <NavLink href="https://fundyouthsports.com/get-started/" target='_blank' className='NavLink'>
                        How It Works
                      </NavLink>
                    </NavItem>
                    <NavItem className='NavItem'>
                      <NavLink href="https://fundyouthsports.com/get-started/" target='_blank' className='NavLink'>
                        Get Started
                      </NavLink>
                    </NavItem>
                    <NavItem className='NavItem'>
                      <NavLink href="https://fundyouthsports.com/contact-us/" target='_blank' className='NavLink'>
                        Contact-Us
                      </NavLink>
                    </NavItem>
                    <NavItem className='NavItem' hidden={isAuthorized}>
                      <NavLink href={`${process.env.REACT_APP_FYS_APP_URL}/login/`} className='NavLink'>
                        Login
                      </NavLink>
                    </NavItem>
                    <NavItem className='NavItem pe-0' hidden={!isAuthorized}>
                      <NavLink href={`${process.env.REACT_APP_FYS_APP_URL}`} className='NavLink d-flex align-items-center'>
                        Go To Dashboard
                        <ArrowRightCircle size={18} className='ms-25' />
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default NavBar
