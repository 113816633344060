// ** React Imports
import { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
// import axios from 'axios'
// import { useDispatch } from 'react-redux'
import Avatar from '@components/avatar'

// ** Icons Imports
import { X } from 'react-feather'

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardText, Form, FormFeedback, Label, Input, Button, Col, Row } from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'
import toast from 'react-hot-toast'

import ComponentSpinner from '../../../@core/components/spinner/Loading-spinner'
import { setGAPageInfo } from '@utils'
import axios from 'axios'

const ToastError = ({ t }) => {
  return (
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color='danger' icon={<Lock size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6></h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t?.id)} />
        </div>
        <span>The user name or password are incorrect. This is easily corrected by typing the correct user name and password.</span>
      </div>
    </div>
  )
}

const BandAuthorize = () => {
  const [searchParams] = useSearchParams()
  const aCode = searchParams.get("code")

  const [isLoading, setIsLoading] = useState(true)
  const [activationResult, setActivationResult] = useState('')
  const [activationMessage, setActivationMessage] = useState('')
  // let organizationObj = {}

//   const navigate = useNavigate()

  useEffect(() => {
    document.title = "FYS Registration - Band Ingtegration"
    setGAPageInfo(document.title)
  }, [])

  useEffect(() => {
    if (aCode !== "") {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/band/auth`, {
            auth_code: aCode
        }).then(res => {
            setActivationResult(res.data.data.activationStatus)
        })
    }
  }, [aCode])

  useEffect(() => {
    if (activationResult !== "") {
        if (activationResult === "error") {
            setActivationMessage("There was an error linking your account. Please close this window and try again.")
        } else if (activationResult === "Existing") {
            setActivationMessage("You have already linked your account. If you are trying to link a new account, please discconect your previous BAND connection and try again.")
        } else {
            setActivationMessage("Thanks for connecting your BAND account. Look for the band logo throughout our site to push messages to your team.")
        }
        setIsLoading(false)
    }
  }, [activationResult])

  return (
    <div className='auth-wrapper auth-basic-md px-2' style={{maxWidth: '700px !important'}}>
      <div className='auth-inner my-2'>
      <Card className='mb-0'>
          <CardBody>
            <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
              <img src={require('@src/assets/images/logo/FYS-horizontal.png').default} style={{width: "auto", height: "50px"}}></img>
              <span className='ms-2 me-2 display-5 text-black'>+</span>
              <img src={require('@src/assets/images/logo/band@2x-80.jpg').default} style={{width: "auto", height: "50px"}}></img>
            </Link>
            {isLoading ? <ComponentSpinner /> : (
                <CardText className='mb-2 text-center'>{activationMessage}<br /><br />
                <Button color="danger">Close Window</Button></CardText>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default BandAuthorize
