// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appDonations/getAllData', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/donation/list?search=${params.q}&status=${params.status}&limit=${params.perPage}&page=${params.page}&sort=${params.sortColumn} ${params.sort}`)
  return response.data
})

export const getData = createAsyncThunk('appDonations/getData', async params => {
  const response = await axios.get('/api/donations/list/data', params)
  return {
    params,
    data: response.data.donations,
    totalPages: response.data.total
  }
})

export const resendDonationReceipt = createAsyncThunk('appDonations/resendDonationReceipt', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/resendReceipt?donation=${id}`)
  return response.data
})

export const getDonation = createAsyncThunk('appDonations/getDonation', async id => {
  const response = await axios.get('/api/donations/donation', { id })
  return response.data.donation
})

export const createAbandonedCart = createAsyncThunk('appDonations/createAbandonedCart', async (donation) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/abandoned_cart/${donation}`)
  return response?.data
})

export const setDonationStatus = createAsyncThunk('appDonations/setDonationStatus', async (donation) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/donation/${donation.id}/status/${donation.status}`)
  return response?.data
})

export const getDonorProfile = createAsyncThunk('appDonations/getDonorProfile', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/donor/${id}`)
  return response.data.data
})

export const addDonation = createAsyncThunk(`${process.env.REACT_APP_BASE_URL}/admin/donation_form/`, async (donation, { dispatch, getState }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/donation_form`, donation)
  // admin/donations/add-donation
  await dispatch(getData(getState().donations.params))
  await dispatch(getAllData())
  return donation
})

export const deleteDonation = createAsyncThunk('appDonations/deleteDonation', async (id, { dispatch, getState }) => {
  await axios.delete('/donations/delete', { id })
  await dispatch(getData(getState().donations.params))
  await dispatch(getAllData())
  return id
})

export const appDonationsSlice = createSlice({
  name: 'appDonations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDonor: {},
    selectedDonation: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDonorProfile.fulfilled, (state, action) => {
        state.selectedDonor = action.payload
      })
      .addCase(getDonation.fulfilled, (state, action) => {
        state.selectedDonation = action.payload
      })
  }
})

export default appDonationsSlice.reducer
