// ** Icons Import
import { DollarSign, Home, Radio, User, Shield, Settings, Users } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <Home />,
    action: 'read',
    resource: 'dashboards',
    navLink: '/dashboard'
  },
  {
    id: 'organizations',
    title: 'Organizations',
    icon: <Shield />,
    action: 'read',
    resource: 'org-organizations',
    navLink: '/orgs/organizations/list'
  },  
  {
    id: 'leagues',
    title: 'Leagues',
    icon: <Shield />,
    action: 'read',
    resource: 'org-leagues',
    navLink: '/orgs/leagues/list'
  },
  {
    id: 'teams',
    title: 'Teams',
    icon: <Shield />,
    action: 'read',
    resource: 'org-teams',
    navLink: '/orgs/teams/list'
  },
  {
    id: 'campaigns',
    title: 'Campaigns',
    icon: <Radio />,
    action: 'read',
    resource: 'campaigns',
    navLink: '/campaigns/list'
  },
  {
    id: 'fundraisers',
    title: 'Players',
    icon: <User />,
    action: 'read',
    resource: 'fundraisers',
    navLink: '/fundraisers/list'
  }
]
