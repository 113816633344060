// ** Icons Import
import { Box, User } from 'react-feather'

export default [
  {
    id: 'fundraisers',
    title: 'Players',
    icon: <User />,
    action: 'read',
    resource: 'fundraisers',
    navLink: '/fundraisers/list'
  },
  {
    id: 'players',
    title: 'Players',
    icon: <User />,
    action: 'read',
    resource: 'players',
    navLink: '/fundraisers/home'
  }
]
