import {
  useContext, 
  useEffect,
  useState
} from 'react'

// ** Third Party Components
import {
  CheckSquare,
  CreditCard,
  HelpCircle,
  Mail,
  MessageSquare,
  Power,
  Settings,
  User,
  ChevronRight,
  DollarSign
} from 'react-feather'
// ** Store & Actions
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
// ** React Imports
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import classnames from 'classnames'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { AbilityContext } from '@src/utility/context/Can'

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Label,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'

// ** Custom Components
import Avatar from '@components/avatar'
// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'
import { handleLogout, handleLogin } from '@store/authentication'
// ** Utils
import { isUserLoggedIn } from '@utils'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const navigate = useNavigate()

    // ** Hook
    const {
      control,
      formState: {  }
    } = useForm({

    })

  // ** State
  const [userData, setUserData] = useState(null)
  const [userId, setuserId] = useState()
  const [orgOptions, setOrgOptions] = useState([])

  const getOrganizationOptions = () => {
    let orgList = []
    userData?.organizations.map(item => {
      const option = { id: item.id, label: `${item.name}` }
      orgList = [...orgList, option]
    })

    setOrgOptions(orgList)
  }

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setuserId(JSON.parse(localStorage.getItem('userData'))?.id)
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
    getOrganizationOptions()
  }, [])

  const [modal, setModal] = useState(null)

  const toggleModal = id => {
    if (modal !== id) {
      setModal(id)
    } else {
      setModal(null)
    }
  }

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar
  const upOrgs = (userData && userData.organizations?.length) || 0
  const upFund = (userData && userData.fundraiser_profile?.length) || 0
  const upDonor = (userData && userData.donor_profile?.length) || 0
  const upAffiliate = (userData && userData.affiliate_profile?.length) || 0
  const userProfiles = upOrgs + upFund + upDonor + upAffiliate

  const [userProfileType, setUserProfileType] = useState('userProfileApp')
  const ability = useContext(AbilityContext)

  const handleContinue = () => {

    async function setAbilities(resData, newAbilities) {
      await ability.update(newAbilities)
      console.log(ability)      
      await dispatch(handleLogin(resData))
      navigate("/")
      window.location.reload()
    }

    if (userProfileType !== "") {

      axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/switch`, {
        loginRole: userProfileType
      })
        .then(res => {
          if (!(res.data.hasError)) {
            const data = { ...res.data.data, accessToken: res.data.data.token, refreshToken: res.data.data.token, abilities: res?.data?.data?.abilities }
            setAbilities(data, res.data.data?.abilities)
          } else {
            toast(t => (
              <ToastError t={t} />
            ))
          }
        })
        .catch(err => {
          if (err?.response?.data?.hasError) {
            if (err?.response?.data?.data?.message) {
              setErrorMessage(err.response?.data?.data?.message)
            } else {
              setErrorMessage('Something went wrong. Please try again!')
            }
          }
        })
    }

    toggleModal('user-profiles')
  }

  const setFundraiser = () => {
    setUserProfileType('fundraiser')
  }

  const { t } = useTranslation()

  return (
    <div>
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && userData['firstName']) || 'John Doe'}</span>
          <span className='user-status'>{(userData && userData.role) || 'Admin'}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem onClick={() => toggleModal('user-profiles')} hidden={(userProfiles <= 1)}>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Switch Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/faq' >
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem>
        <DropdownItem tag={Link} to={`/apps/user/view/${userId}`}>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Account Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} replace={true} to='/login' onClick={() => dispatch(handleLogout())}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
      <Modal
          isOpen={modal === 'user-profiles'}
          toggle={() => toggleModal('user-profiles')}
          className={'modal-dialog-centered modal-lg'}
          key='user-profiles'
        >
          <ModalHeader toggle={() => toggleModal('user-profiles')}>

          </ModalHeader>
          <ModalBody>
            <h1 className='text-center mb-1'>{t('Select User Profile')}</h1>
            <p className='text-center mb-3'>
            {t('please choose a user profile')}
              <br />
              {t('in order to have a more customized experience')}
            </p>
            <div className='custom-options-checkable'>
              <input
                type='radio'
                id='organization'
                name='userProfileType'
                checked={userProfileType === 'organization'}
                className='custom-option-item-check'
                onChange={() => setUserProfileType('organization')}
              />
              {
              (upOrgs > 0) ? <>
              <label
                htmlFor='organization'
                className='custom-option-item d-flex align-items-center flex-column flex-sm-row px-3 py-2 mb-2'
              >
                <span>
                  <MessageSquare className='font-large-2 me-sm-2 mb-2 mb-sm-0' />
                </span>
                <span>
                  <span className='custom-option-item-title d-block h3'>{t('Organization')}</span>
                  <span className='mt-75'>{t('Manage the data for an organization you have administrative rights to')}.</span>

                  <Controller
                    control={control}
                    name='organizationId'
                    id='organizationId'
                    render={({ field }) => (
                      <Select
                        isClearable={false}
                        classNamePrefix="select"
                        options={orgOptions}
                        className={classnames('react-select')}
                        {...field}
                      />
                    )}
                  />
                </span>
              </label>
              </> : null
            }
              <input
                type='radio'
                id='fundraiser'
                name='userProfileType'
                checked={userProfileType === 'fundraiser'}
                className='custom-option-item-check'
                onChange={() => setFundraiser()}
              />
              {
                (upFund > 0) ? <>
              <label hidden={(upFund < 1)}
                htmlFor='fundraiser'
                className='custom-option-item d-flex align-items-center flex-column flex-sm-row px-3 py-2 mb-2'
              >
                <span>
                  <Settings className='font-large-2 me-sm-2 mb-2 mb-sm-0' />
                </span>
                <span>
                  <span className='custom-option-item-title d-block h3'>{t('Fundraiser')}</span>
                  <span className='mt-75'>
                    {t('Choose this profile to view your personal fundraising activities')}.
                  </span>
                </span>
              </label>
              </> : null
            }
            <input
                type='radio'
                id='affiliate'
                name='userProfileType'
                checked={userProfileType === 'affiliate'}
                className='custom-option-item-check'
                onChange={() => setUserProfileType('affiliate')}
              />
              {
                (upAffiliate > 0) ? <>
              <label hidden={(upFund < 1)}
                htmlFor='affiliate'
                className='custom-option-item d-flex align-items-center flex-column flex-sm-row px-3 py-2 mb-2'
              >
                <span>
                  <DollarSign className='font-large-2 me-sm-2 mb-2 mb-sm-0' />
                </span>
                <span>
                  <span className='custom-option-item-title d-block h3'>{t('Affiliate')}</span>
                  <span className='mt-75'>
                    {t('Choose this profile to view and manage your affiliate program')}.
                  </span>
                </span>
              </label>
              </> : null
            }
              <input
                type='radio'
                id='donor'
                name='userProfileType'
                checked={userProfileType === 'donor'}
                className='custom-option-item-check'
                onChange={() => setUserProfileType('donor')}
              />
              {
              (upDonor > 0) ? <>
              <label
                htmlFor='donor'
                className='custom-option-item d-flex align-items-center flex-column flex-sm-row px-3 py-2 mb-2'
              >
                <span>
                  <MessageSquare className='font-large-2 me-sm-2 mb-2 mb-sm-0' />
                </span>
                <span>
                  <span className='custom-option-item-title d-block h3'>{t('Donor')}</span>
                  <span className='mt-75'>{t('Choose this profile to manage the donations you have made within FYS')}</span>
                </span>
              </label>
              </> : null
            }
            </div>

          </ModalBody>
          <ModalFooter>
          <Button color='primary' className='float-end' onClick={handleContinue}>
              <span className='me-50'>{t('Continue')}</span>
              <ChevronRight className='rotate-rtl' size={14} />
            </Button>
          </ModalFooter>
        </Modal>
        </div>

  )
}

export default UserDropdown
