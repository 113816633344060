// ** React Imports
import { lazy } from 'react'

const AffiliateList = lazy(() => import('../../views/affiliates/list'))
const AffiliateView = lazy(() => import('../../views/affiliates/view'))
const AffiliateProfile = lazy(() => import('../../views/public-pages/AffiliateProfile'))


const AffiliateRoutes = [
  {
    element: <AffiliateList />,
    path: '/affiliates/list',
    meta: {
      action: 'read',
      resource: 'affiliates'
    }
  },
  {
    path: '/affiliate/:slug',
    element: <AffiliateProfile />, 
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <AffiliateView />,
    path: '/affiliates/view/:id',
    meta: {
      action: 'read',
      resource: 'affiliates'
    }
  },
  {
    element: <AffiliateView />,
    path: '/affiliates/profile',
    meta: {
      action: 'read',
      resource: 'affiliates'
    }
  }
]

export default AffiliateRoutes
