// You can customize the template with the help of this file

import { getUserData } from '@utils'

const userData = getUserData()

//Template config options
const themeConfig = {
  app: {
    appName: 'FUNDRAISER CONSOLE',
    appLogoImage: require('@src/assets/images/logo/FYS-horizontal.png').default,
    appLogoImageDark: require('@src/assets/images/logo/FYS-Logo-White-White-Star.png').default
  },
  baseApi: 'http://api.fundyouthsports.com/api/v1',
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal (getUserData().firstName === "Thomas") ? 'vertical' : 
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: (userData && userData.role === "Super Admin"),
    scrollTop: true, // Enable scroll to top button,
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
