// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user/list?q=${params.q}&page=${params.page}&perPage=${params.perPage}&sort=${params.sort}&sortColumn=${params.sortColumn}`)
    return {
      params,
      data: response.data.data,
      totalPages: response.data.data.total / params.perPage
    }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user/${id}`, { id })
  return response.data.data
})

export const getSalespeople = createAsyncThunk('appUsers/getSalespeople', async (doRefresh, { getState }) => {
  if (doRefresh || !getState().salespeople || (getState().salespeople && getState().salespeople.length === 0)) {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/salespeople`)
    return response.data.data
  } else {
    return getState().salespeople
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  // await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, user)

  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user/create`, user)
  // user_organization/create
  await dispatch(getData(getState().users.params))
  return user
})

export const completeStripeSetup = createAsyncThunk('appUsers/completeStripeSetup', async (id, { dispatch }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/stripe/complete-setup`, { organizationId: id })
  await dispatch(getUser(id))
})

export const createPayment = createAsyncThunk('appUsers/createPayment', async (paymentParams, { dispatch }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/${paymentParams.stripeAccount}/payout`, paymentParams)
  await dispatch(getUser(paymentParams.id))
})

export const releaseEscrowFunds = createAsyncThunk('appUsers/releaseEscrowFunds', async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/stripe/processTransfers`, {
    isEscrow: false,
    account: id
  })
  return response
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { dispatch }) => {
  // await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/create`, user)

  await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/user`, user)
  // user_organization/create
  await dispatch(getUser(user.id))
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/user/${id}`, { id })
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    userOrganization:[],
    fundraiserProfile:[],
    donorProfile:[],
    salespeople:[],
    // teamCampaigns:[],
    // teamUsers:[],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSalespeople.fulfilled, (state, action) => {
        state.salespeople = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.allData = action.payload.data.users
        state.data = action.payload.data.users
        state.params = action.payload.params
        state.total = action.payload.data.total
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.userOrganization = action.payload?.organizations
        state.fundraiserProfile = action.payload?.fundraiser_profile
        state.donorProfile = action.payload?.donor_profile
      })
  }
})

export default appUsersSlice.reducer
