import React, {
  useEffect,
  useState
} from 'react'
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'

import { setGAPageInfo } from '@utils'

export default function CheckoutForm(props) {
    const stripe = useStripe()
    const elements = useElements()

    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
//props
useEffect(() => {
    document.title = "FYS Donation Checkout"
    setGAPageInfo(document.title)
    window.gtag('event', 'Donation', {
        donation_amount: 0,
        conver_fees: 0
      })
}, [])
    useEffect(() => {
        
        if (!stripe) {
            return
        }

        const clientSecret = props.clientSecret

        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            console.log(paymentIntent)
            if (paymentIntent.charge) {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!")
                        break
                    case "processing":
                        setMessage("Your payment is processing.")
                        break
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.")
                        break
                    default:
                        setMessage("Something went wrong.")
                        break
                }
            }
        })
    }, [stripe])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${process.env.REACT_APP_FYS_APP_URL}/donations/${props.donation}/thank-you`
            }
        })
        console.log(error)

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message)
        } else {
            setMessage("An unexpected error occured.")
        }

        setIsLoading(false)
    }
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <br />
            <div class="text-center">
                <button className="myCenter stripe-donat-btn" disabled={isLoading || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Donate Now"}
                    </span>
                </button>
            </div>
            {/* Show any error or success messages */}
            {message && <div id="payment-message" className="stripe-donat-error text-center">
            <span className='text-danger'>{message}</span>
            </div>}
        </form>
    )
}