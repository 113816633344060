// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appDivisions/getAllData', async () => {
  // const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/divisions`)
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_TEAM}`)
  return response.data.data
})

export const getData = createAsyncThunk('appDivisions/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/user_organization/filter?organizationType=${process.env.REACT_APP_ORG_TYPE_TEAM}`)
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
})

export const getDivision = createAsyncThunk('appDivisions/getDivision', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/divisions/${id}`)
  return response?.data?.data
})

export const addCampaign = createAsyncThunk('appDivisions/addCampaign', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getData(getState().divisions.params))
  await dispatch(getAllData())
  return response?.data
})

export const validateCustomUrl = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/organization/validate-url/${slug}?showDetail=0`)
}

export const editCampaign = createAsyncThunk('appDivisions/editCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getData(getState().divisions.params))
  await dispatch(getAllData())
  return response?.data
})

export const uploadDivisionProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/avatar`, formData)
}

export const uploadProfileImage = createAsyncThunk('appDivisions/uploadProfileImage', async (formData, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${getState().selectedUser.id}/avatar`, formData)
  await dispatch(getData(getState().divisions.params))
  await dispatch(getAllData())
  return response.data
})

export const getDivisionUsers = createAsyncThunk('appDivisions/getDivisionUsers', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/organization/${id}/users`)

  return response.data
})

export const addDivision = createAsyncThunk('appDivisions/addDivision', async (division, { dispatch, getState }) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/division/create`, division)
  await dispatch(getData(getState().divisions.params))
  await dispatch(getAllData())
  return division
})

export const addPlayer = createAsyncThunk('appDivisions/addPlayer', async (playerData, { dispatch }) => {
  const { divisionId, ...player } = playerData
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/division/${divisionId}/player`, player)
  await dispatch(getDivision(divisionId))
  return response?.data?.data
})

export const importPlayers = createAsyncThunk('appDivisions/importPlayers', async (playerObject, { dispatch }) => {
  const { divisionId, ...player_data } = playerObject
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/divisions/${divisionId}/player_import`, player_data)
  await dispatch(getDivision(divisionId))
  return response?.data?.data
})

export const addExistingPlayers = createAsyncThunk('appDivisions/addExistingPlayers', async (playerData) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign_fundraiser/bulkadd`, playerData)
  return response?.data?.data
})

export const addUser = createAsyncThunk('appDivisions/addUser', async (userData, { dispatch }) => {
  const { divisionId, ...user } = userData
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/division/${divisionId}/user`, user)
  await dispatch(getDivisionUsers(divisionId))
  state.selectedUser.users = response?.data?.data
  return response?.data?.data
})

export const resendPlayerInvites = createAsyncThunk('appDivisions/resendPlayerInvites', async (divisionId, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_fundraiser/${divisionId}/resend_invites`)
  await dispatch(getDivision(divisionId))
  return response?.data
})

export const removePlayer = createAsyncThunk('appDivisions/removePlayer', async (playerData, { dispatch }) => {
  const { divisionId, orgFundraiserId } = playerData
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_fundraiser/${orgFundraiserId}`)
  await dispatch(getDivision(divisionId))
  return response?.data
})

export const updateOrganization = createAsyncThunk('appDivisions/updateOrganization', async (updatedDivision, { dispatch }) => {
  const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/organization`, updatedDivision)
  await dispatch(getDivision(updatedDivision.id))
  return response?.data?.data
})

export const appDivisionsSlice = createSlice({
  name: 'appDivisions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.total = action.payload.length
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.data.length
      })
      .addCase(getDivision.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appDivisionsSlice.reducer
