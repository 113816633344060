// ** React Imports
import { useState } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import { Settings, X } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Reactstrap Imports
import { Input, Label } from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'

const Customizer = () => {
  // ** State
  const [openCustomizer, setOpenCustomizer] = useState(false)

  // ** Toggles Customizer
  const handleToggle = e => {
    e.preventDefault()
    setOpenCustomizer(!openCustomizer)
  }

  return (
    <div
      className={classnames('customizer d-none d-md-block', {
        open: openCustomizer
      })}
    >
      <a href='/' className='customizer-toggle d-flex align-items-center justify-content-center' onClick={handleToggle}>
        <Settings size={14} className='spinner' />
      </a>
      <PerfectScrollbar className='customizer-content' options={{ wheelPropagation: false }}>
        <div className='customizer-header px-2 pt-1 pb-0 position-relative'>
          <h4 className='mb-0'>Integrations</h4>
          <p className='m-0'>.</p>
          <a href='/' className='customizer-close' onClick={handleToggle}>
            <X />
          </a>
        </div>

        <hr />

        {/* <div className='px-2'>
          <div className='mb-2'>
            <p className='fw-bold'>Skin</p>
            <div className='d-flex'>{renderSkinsRadio()}</div>
          </div>

          <div className='mb-2'>
            <p className='fw-bold'>Content Width</p>
            <div className='d-flex'>
              <div className='form-check me-1'>
                <Input
                  type='radio'
                  id='full-width'
                  checked={contentWidth === 'full'}
                  onChange={() => setContentWidth('full')}
                />
                <Label className='form-check-label' for='full-width'>
                  Full Width
                </Label>
              </div>
              <div className='form-check'>
                <Input
                  id='boxed'
                  type='radio'
                  checked={contentWidth === 'boxed'}
                  onChange={() => setContentWidth('boxed')}
                />
                <Label className='form-check-label' for='boxed'>
                  Boxed
                </Label>
              </div>
            </div>
          </div>

          <div className='form-switch mb-2 ps-0'>
            <div className='d-flex'>
              <p className='fw-bold me-auto mb-0'>RTL</p>
              <Input type='switch' id='rtl' name='RTL' checked={isRtl} onChange={() => setIsRtl(!isRtl)} />
            </div>
          </div>

          <div className='mb-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='fw-bold mb-0'>Router Transition</p>
              <Select
                theme={selectThemeColors}
                className='react-select'
                classNamePrefix='select'
                defaultValue={transitionOptions[0]}
                value={transitionValue}
                options={transitionOptions}
                isClearable={false}
                onChange={({ value }) => setTransition(value)}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className='px-2'>
          <p className='fw-bold'>Menu Layout</p>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <div className='form-check me-1'>
                <Input
                  type='radio'
                  id='vertical-layout'
                  checked={layout === 'vertical'}
                  onChange={() => {
                    setLayout('vertical')
                    setLastLayout('vertical')
                  }}
                />
                <Label className='form-check-label' for='vertical-layout'>
                  Vertical
                </Label>
              </div>
              <div className='form-check'>
                <Input
                  type='radio'
                  id='horizontal-layout'
                  checked={layout === 'horizontal'}
                  onChange={() => {
                    setLayout('horizontal')
                    setLastLayout('horizontal')
                  }}
                />
                <Label className='form-check-label' for='horizontal-layout'>
                  Horizontal
                </Label>
              </div>
            </div>
          </div>
          {layout !== 'horizontal' ? (
            <div className='form-switch mb-2 ps-0'>
              <div className='d-flex align-items-center'>
                <p className='fw-bold me-auto mb-0'>Menu Collapsed</p>
                <Input
                  type='switch'
                  id='menu-collapsed'
                  name='menu-collapsed'
                  checked={menuCollapsed}
                  onChange={() => setMenuCollapsed(!menuCollapsed)}
                />
              </div>
            </div>
          ) : null}

          <div className='form-switch mb-2 ps-0'>
            <div className='d-flex align-items-center'>
              <p className='fw-bold me-auto mb-0'>Menu Hidden</p>
              <Input
                type='switch'
                id='menu-hidden'
                name='menu-hidden'
                checked={isHidden}
                onChange={() => setIsHidden(!isHidden)}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className='px-2'>
          {layout !== 'horizontal' ? (
            <div className='mb-2'>
              <p className='fw-bold'>Navbar Color</p>
              <ul className='list-inline unstyled-list'>{renderNavbarColors()}</ul>
            </div>
          ) : null}

          <div className='mb-2'>
            <p className='fw-bold'>{layout === 'horizontal' ? 'Menu' : 'Navbar'} Type</p>
            <div className='d-flex'>{renderNavbarTypeRadio()}</div>
          </div>
        </div>

        <hr />

        <div className='px-2'>
          <div className='mb-2'>
            <p className='fw-bold'>Footer Type</p>
            <div className='d-flex'>{renderFooterTypeRadio()}</div>
          </div>
        </div> */}
      </PerfectScrollbar>
    </div>
  )
}

export default Customizer
