// ** Icons Import
import { Box, Shield, Circle, User, Settings, Lock, CheckSquare, Users } from 'react-feather'

export default [
  {
    id: 'settings',
    title: 'Settings',
    icon: <Settings />,
    children: [
      {
        id: 'users',
        title: 'User',
        icon: <Lock />,
        navLink: '/apps/user/list'
      },
      {
        id: 'affiliates',
        title: 'Affiliates',
        icon: <Users />,
        navLink: '/affiliates/list'
      },
      {
        id: 'onboardings',
        title: 'Registrations',
        icon: <Users />,
        navLink: '/onboarding/list'
      },
      {
        id: 'todo',
        title: 'Onboarding Tasks',
        icon: <CheckSquare />,
        navLink: '/apps/todo'
      }
    ]
  }
]