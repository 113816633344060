// ** Icons Import
import { Home, Users } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Affiliate Dashboard',
    icon: <Home />,
    action: 'read',
    resource: 'dashboards',
    navLink: '/dashboard/affiliate'
  },
  {
    id: 'affiliates',
    title: 'My Affiliate Profile',
    icon: <Users />,
    action: 'read',
    resource: 'affiliates',
    navLink: '/affiliates/profile'
  }
]
