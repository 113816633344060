// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appOnboardings/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/onboarding/`)
  return response.data
})

export const getData = createAsyncThunk('appOnboardings/getData', async (params) => {
  const qString = `?status=${params.status}&q=${params.q}&perPage=${params.perPage}&page=${params.page}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  console.log(qString)
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/onboarding/${qString}`)
  return {
    params,
    data: response.data.data
  }
})

export const getOnboarding = createAsyncThunk('appOnboardings/getOnboarding', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/onboarding/${id}`)
  return response.data.data
})

export const editOnboarding = createAsyncThunk('appOnboardings/editOnboarding', async (onboarding, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/onboarding/`, onboarding)
  await dispatch(getData(getState().onboardings.params))
  return response?.data
})

export const deleteOnboarding = createAsyncThunk('appOnboardings/deleteOnboarding', async (id, { dispatch, getState }) => {
  await axios.delete('/onboardings/delete', { id })
  await dispatch(getAllData())
  await dispatch(getData(getState().onboardings.params))
  return id
})

export const appOnboardingsSlice = createSlice({
  name: 'appOnboardings',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    conversions: [],
    programs: [],
    selectedUser: null,
    selectedOnboarding: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getOnboarding.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appOnboardingsSlice.reducer
