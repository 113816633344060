// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getUserData } from '@utils'

// ** Axios Imports
import axios from 'axios'

export const getAllCampaignData = createAsyncThunk('appCampaigns/getAllData', async () => {
  const user = getUserData()
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/campaign/listbyuser/?userId=${user.id}&userRole=${user.role}`)
  return response.data
})

export const getData = createAsyncThunk('appCampaigns/getData', async (params, { getState }) => {
  let filtered = getState().campaigns.allData
  if (params.status !== "") {
    filtered = getState().campaigns.allData.filter(campaign => {
      return campaign.status === params.status
    })
  }
  if (params.q !== "") {
    filtered = filtered.filter(campaign => {
      return campaign.organization_name.includes(params.q) || campaign.title.includes(params.q) || campaign.first_name.includes(params.q) || campaign.last_name.includes(params.q)
    })
  }
  return {
    params,
    data: filtered,
    totalPages: getState().campaigns.total
  }
})

export const validateCustomUrl = (slug, entityType = 'campaigns') => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/${entityType}/validate-url/${slug}?showDetail=0`)
}

export const getCampaign = createAsyncThunk('appCampaigns/getCampaign', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/${id}`)
  return response.data.data
})

export const editCampaign = createAsyncThunk('appCampaigns/editCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getCampaign(campaign.id))
  return response?.data
})

export const editOrganizationCampaign = createAsyncThunk('appCampaigns/editOrganizationCampaign', async (campaign, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_campaign/`, campaign)
  await dispatch(getCampaign(campaign.campaign))
  return response?.data
})

export const getDivisionCampaign = createAsyncThunk('appCampaigns/getDivisionCampaign', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/${params.id}/division/${params.divisionId}`)
  return response.data.data
})

export const getFeeRequests = createAsyncThunk('appCampaigns/getFeeRequests', async (campaign) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fee_requests/${campaign}`)
  return response.data.data
})

export const resendCampaignInvite = createAsyncThunk('appCampaigns/resendCampaignInvite', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign_fundraisers/resend_invite`, campaign)
  await dispatch(getDivisionCampaign({ id: campaign.campaign, divisionId: campaign.divisionId }))
  return response?.data
})

export const sendBulkCampaignMessage = createAsyncThunk('appCampaigns/sendBulkCampaignMessage', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign_fundraisers/bulk_message`, campaign)
  await dispatch(getDivisionCampaign({ id: campaign.campaign, divisionId: campaign.divisionId }))
  return response?.data
})

export const removeOrganizationCampaign = createAsyncThunk('appCampaigns/removeOrganizationCampaign', async (campaignData, { dispatch }) => {
  const { campaign, orgCampaignId } = campaignData
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_campaign/${orgCampaignId}`)
  await dispatch(getCampaign(campaign))
  return response?.data
})

export const addOrganizationCampaigns = createAsyncThunk('appCampaigns/addOrganizationCampaigns', async (orgData, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_campaign/bulkadd`, orgData)
  await dispatch(getCampaign(orgData.campaign))
  return response?.data
})

export const uploadCampaignProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/campaign/${id}/avatar`, formData)
}

export const addCampaign = createAsyncThunk('appCampaigns/addCampaign', async (campaign, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign/`, campaign)
  await dispatch(getAllData())
  return response?.data
})

export const deleteCampaign = createAsyncThunk('appCampaigns/deleteCampaign', async (id, { dispatch }) => {
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/campaign`, { id })
  await dispatch(getAllData())
  return id
})

export const appCampaignsSlice = createSlice({
  name: 'appCampaigns',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    activeCampaigns: [],
    selectedUser: null,
    selectedCampaign: null,
    campaignsLoaded: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllCampaignData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
        state.campaignsLoaded = true
        state.activeCampaigns = action.payload.data.filter(campaign => {
          return campaign.status === 'active' || campaign.status === 'draft'
        })
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDivisionCampaign.fulfilled, (state, action) => {
        state.selectedCampaign = action.payload
      })
      .addCase(getFeeRequests.fulfilled, (state, action) => {
        state.selectedUser.feeRequests = action.payload
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appCampaignsSlice.reducer
