import './public-pages.scss'
import '@styles/react/libs/input-number/input-number.scss'

import React, { useEffect, useState } from 'react'

import { setGAPageInfo } from '@utils'

import axios from 'axios'
import { Lock, X } from 'react-feather'
import { Container, Row, Col } from 'reactstrap'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import defaultAvatar from '@src/assets/images/logo/fys-avatar-blank.png'
import CheckoutForm from './CheckoutForm'
import Footer from './Footer'
import NavBar from './NavBar'
import { useParams, useNavigate } from 'react-router-dom'
import Avatar from "@components/avatar"
import toast from 'react-hot-toast'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY)
const AbandonedCart = () => {
  const { donation_id } = useParams()
  const navigate = useNavigate()

  const [isContinue, setIsContinue] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [donationAmount, setDonationAmount] = useState(20)
  const [clientSecret, setClientSecret] = useState("")
  const [donation, setDonation] = useState("")
  const [campaign, setCampaign] = useState()
  const [fundraiser, setFundraiser] = useState()
  const [organization, setOrganization] = useState()
  const [formValues, setFormValues] = useState({})

  const ToastError = ({ t }) => {
    return (
      <div className='d-flex'>
        <div className='me-1 text-xs-center'>
          <Avatar size='sm' color='danger' icon={<Lock size={12} />} />
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <h6></h6>
            <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t?.id)} />
          </div>
          <span>Your submission failed. Please check your form fields and try again.</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (campaign) {
      document.title = `FundYouthSports - ${campaign.title} Abandoned Cart - Donate`
      setGAPageInfo(document.title)
      window.gtag('event', 'Abandoned Cart View', {
        donation_id: donation.id
      })
    }
  }, [donation])

  async function getAbandonedCart() {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/abandoned_cart/${donation_id}`)
    if (res.data.hasError) {
      navigate(`/cart/notfound`)
    } else {
      setDonation(res.data.data)
      setOrganization(res.data.data.organization)
      setFundraiser(res.data.data.fundraiser)
      setCampaign(res.data.data.campaign)
      setDonationAmount((res.data.data.donationAmount) / 100)
      const piObject = JSON.parse(res.data.data.paymentStatus)
      console.log(piObject)
      setIsComplete(res.data.data.status === 1)
      setClientSecret(piObject.client_secret)
      setIsContinue(true)
    }
  }

  useEffect(() => {
    if (donation_id) {
      getAbandonedCart()
      setFormValues({ donation: donation_id, client_secret: clientSecret })
    }
  }, [donation_id])

  useEffect(() => {

    if (clientSecret) {
      setIsContinue(!isContinue)
    }

  }, [clientSecret])

  const updateClientSecret = (value) => {
    setClientSecret(value)
  }

  const appearance = {
    theme: 'stripe'
  }

  const options = {
    clientSecret,
    appearance
  }

  return (
    <div>
      <NavBar></NavBar>
      <div id='donationform'>
      <Container fluid="md" className='container' style={{maxWidth: "1100px"}}>
        <Row>
          <Col md="6">
            <div className='myComponent'>
              {(donation.organization !== "" && donation.fundraiser === "" && organization) && (
                <Container fluid="md" className='container'>
                  <Row>
                    <Col md="4" className=''>
                      <img src={campaign?.logoImage || defaultAvatar} style={{ maxWidth: "100%" }}></img>
                    </Col>
                    <Col md="8" className=''>
                      <p className='mb-25'>THANK YOU FOR SUPPORTING</p>
                      <h3 className='mt-0 mb-2'>{organization?.campaign?.title}</h3>
                      <p className='text-small mb-25'>Your donation directly supports</p>
                      <h5 className='mb-2'>{organization?.organization?.name}</h5>
                    </Col>
                    <Col md="12">
                      <p className='d-none d-md-block mt-2' style={{ whiteSpace: "pre-line" }}>{organization?.campaign?.shortDescription}</p>
                    </Col>
                  </Row>
                </Container>
              )}
              {(!donation.organization && !donation.fundraiser && campaign) && (<Container fluid="md" className='container'>
                <Row>
                  <Col md="5" className='text-center text-sm-left'>
                    <img src={campaign?.logoImage || defaultAvatar} style={{ maxWidth: "100%" }}></img>
                  </Col>
                  <Col md="7" className='text-center text-sm-left'>
                    <p className='mb-25 mt-2 mt-sm-0'>THANK YOU FOR SUPPORTING</p>
                    <h3 className='mt-0 mb-2'>{campaign?.title}</h3>
                  </Col>
                  <Col md="12">
                    <p className='d-none d-md-block mt-2' style={{ whiteSpace: "pre-line" }}>{campaign?.shortDescription}</p>
                  </Col>
                </Row>
              </Container>)}
              {(donation.fundraiser !== "" && fundraiser) && (<Container fluid="md" className='container'>
                <Row>
                  <Col md="12" className=' text-center text-sm-left'>
                    <p className='mb-25 mt-2 mt-sm-0'>THANK YOU FOR SUPPORTING</p>
                    <h3 className='mt-0 mb-2'>{fundraiser?.firstName} {fundraiser?.lastName}</h3>
                  </Col>
                  <Col md="12" className='text-center text-sm-left'>
                    <img src={campaign?.logoImage} style={{ maxWidth: "100%", borderRadius: "20px" }}></img>
                  </Col>
                  <Col md="12">
                    <p className='d-none d-md-block mt-2' style={{ whiteSpace: "pre-line" }}>{fundraiser?.fundraisingReason}</p>
                  </Col>
                </Row>
              </Container>)}
            </div>
          </Col>
          <Col md="6">
            <div className='myComponent' style={{ background: "white" }}>
              {isContinue && isComplete && (<Container fluid="md" className='container'>
                <div className="text-center" style={{ paddingBottom: "2rem" }}>
                  <h3 className='myCenter' style={{ color: "black", fontWeight: "bold" }}>Thanks For Your Donation</h3>
                  <p className="text-center">
                    You have already completed your donation of:
                  </p>
                  <h2 className='text-center'>${donationAmount}</h2>
                  <p className='text-center text-small' hidden={donation.coverFeeAmount === 0}>You have also chosen to cover the processing fees associated with this transaction, totaling:</p>
                  <h4 className='text-center' hidden={donation.coverFeeAmount === 0}>${donation.donationAmount / 100}</h4>
                  <p className='text-center text-small'>If you would like to make an additional donation, please click the button below.</p>
                </div>
                <div className='row' style={{ paddingBottom: "0rem" }}>
                  <div className='myFlex'>
                    <a className="myCenter donate_btn" href="thankyou">Donate Now</a>
                  </div>
                </div>
              </Container>)}

              {isContinue && !isComplete && (<Container fluid="md" className='container'>
                <div className="text-center" style={{ paddingBottom: "3rem" }}>
                  <h3 className='myCenter' style={{ color: "black", fontWeight: "bold" }}>Continue Your Donation</h3>
                  <p className="text-center">
                    Sorry, but it seems your previous donation attempt was not completed. We would love to still have your support for our campaign with your previous donation of:
                  </p>
                  <h2 className='text-center'>${donationAmount}</h2>
                  <p className='text-center text-small' hidden={donation.coverFeeAmount === 0}>You have also chosen to cover the processing fees associated with this transaction, totaling:</p>
                  <h4 className='text-center' hidden={donation.coverFeeAmount === 0}>${(donation.donationAmount + donation.coverFeeAmount) / 100}</h4>
                  <h5 className='myCenter mt-3' style={{ color: "black", fontWeight: "bold" }}>Payment Information</h5>
                </div>
                {(
                  <div className='row justify-content-md-center'>
                    <div className='col-md-10 col-sm-12'>
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm formData={formValues} clientSecret={clientSecret} donation={donation.id} donationAmount={(donation.donationAmount + donation.coverFeeAmount)} updateClientSecret={updateClientSecret} />
                      </Elements>
                    </div>
                  </div>
                )}
                <Row>
                  <Col>
                    <p className='mt-1 text-small text-center'>By continuing, you agree with the FundYouthSports <a href="https://fundyouthsports.com/terms-and-conditions/" target="_blank">terms of use</a> and <a href="https://fundyouthsports.com/privacy-policy/" target="_blank">privacy policy</a>.</p>
                  </Col>
                </Row>
                <div className='row' style={{ paddingBottom: "0rem" }}>
                  <div className='col-md-2 myFlex'>
                    {/* <a className="myLeft donate_btn" href="thankyou">Donate Now</a> */}
                  </div>
                </div>
              </Container>)}
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default AbandonedCart
