// ** Icons Import
import {
  Box,
  Circle,
  FileText,
  Shield
} from 'react-feather'

export default [
    {
        id: 'organizations',
        title: 'Organizations',
        icon: <Shield />,
        action: 'read',
        resource: 'org-organizations',
        navLink: '/orgs/organizations/list'
    },
          
      {
        id: 'leagues',
        title: 'Leagues',
        icon: <Shield />,
        action: 'read',
        resource: 'org-leagues',
        navLink: '/orgs/leagues/list'
      },
      {
        id: 'teams',
        title: 'Teams',
        icon: <Shield />,
        action: 'read',
        resource: 'org-teams',
        navLink: '/orgs/teams/list'
      },
      {
        id: 'divisions',
        title: 'Divisions',
        icon: <Shield />,
        action: 'read',
        resource: 'org-divisions',
        navLink: '/orgs/divisions/list'
      }
    ]
