import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'))
const DashboardAffiliate = lazy(() => import('../../views/dashboard/affiliate'))
const DashboardEcommerce = lazy(() => import('../../views/dashboard/ecommerce'))
const DashboardFundraiser = lazy(() => import('../../views/dashboard/fundraiser'))
const DashboardSystem = lazy(() => import('../../views/dashboard/system'))
const DashboardTeam = lazy(() => import('../../views/dashboard/team'))

const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <DashboardTeam />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  },
  {
    path: '/dashboard/admin',
    element: <DashboardSystem />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  },
  {
    path: '/dashboard/analytics',
    element: <DashboardAnalytics />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  },
  {
    path: '/dashboard/affiliate',
    element: <DashboardAffiliate />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  },
  {
    path: '/dashboard/fundraiser',
    element: <DashboardFundraiser />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  },
  {
    path: '/dashboard/ecommerce',
    element: <DashboardEcommerce />,
    meta: {
      action: 'read',
      resource: 'dashboards'
    }
  }
]

export default DashboardRoutes
