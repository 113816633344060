// ** Icons Import
import { Home, Radio, User } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Fundraiser Dashboard',
    icon: <Home />,
    action: 'read',
    resource: 'dashboards',
    navLink: '/dashboard/fundraiser'
  },
  {
    id: 'players',
    title: 'My Players',
    icon: <User />,
    action: 'read',
    resource: 'players',
    navLink: '/fundraisers/home'
  },
  {
    id: 'campaigns',
    title: 'My Campaigns',
    icon: <Radio />,
    action: 'read',
    resource: 'campaigns',
    navLink: '/campaigns/list'
  }
]
