// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '@src/views/apps/user/store'
import todo from '@src/views/apps/todo/store'
import fundraisers from '@src/views/fundraisers/store'
import affiliates from '@src/views/affiliates/store'
import onboardings from '@src/views/onboarding/store'
import campaigns from '@src/views/campaigns/store'
import donations from '@src/views/donations/store'
import organizations from '@src/views/orgs/organizations/store'
import leagues from '@src/views/orgs/leagues/store'
import teams from '@src/views/orgs/teams/store'
import divisions from '@src/views/orgs/divisions/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
  auth,
  users,
  todo,
  navbar,
  layout,
  dataTables,
  permissions, 
  fundraisers, 
  organizations, 
  leagues, 
  teams, 
  divisions, 
  campaigns, 
  affiliates,
  onboardings,
  donations
}

export default rootReducer
