// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appAffiliates/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/`)
  return response.data
})

export const getData = createAsyncThunk('appAffiliates/getData', async (params, { getState }) => {
  let filtered = getState().affiliates.allData
  if (params.status !== "") {
    filtered = getState().affiliates.allData.filter(affiliate => {
      return affiliate.status === params.status
    })
  }
  if (params.q !== "") {
    filtered = filtered.filter(affiliate => {
      return affiliate.first_name.includes(params.q) || affiliate.last_name.includes(params.q)
    })
  }
  return {
    params,
    data: filtered,
    totalPages: getState().affiliates.total
  }
})

export const getAffiliate = createAsyncThunk('appAffiliates/getAffiliate', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/${id}`)
  return response.data.data
})

export const validateCustomUrl = (slug, entityType = 'affiliate') => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/${entityType}/validate-url/${slug}?showDetail=0`)
}

export const getAffiliatePrograms = createAsyncThunk('appAffiliates/getAffiliatePrograms', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/program`)
    return response.data
})

export const addAffiliate = createAsyncThunk('appAffiliates/addAffiliate', async (affiliate, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate`, affiliate)
  await dispatch(getAllData())
  return response?.data
})

export const createOffer = createAsyncThunk('appAffiliates/createOffer', async (affiliate, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/offer`, affiliate)
  await dispatch(getAffiliate(affiliate.affiliateId))
  return response?.data
})

export const acceptOffer = createAsyncThunk('appAffiliates/acceptOffer', async (affiliate, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/offer/accept`, affiliate)
  await dispatch(getAffiliate(affiliate.affiliateId))
  return response?.data
})

export const sendInvite = createAsyncThunk('appAffiliates/sendInvite', async (affiliate) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/invite`, affiliate)
  return response?.data
})

export const editAffiliate = createAsyncThunk('appAffiliates/editAffiliate', async (affiliate, { dispatch }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/`, affiliate)
  await dispatch(getAffiliate(affiliate.id))
  return response?.data
})

export const getAffiliateConversions = createAsyncThunk('appAffiliates/getAffiliateConversions', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/${id}/conversions`)
  return response?.data
})

export const deleteAffiliate = createAsyncThunk('appAffiliates/deleteAffiliate', async (id, { dispatch, getState }) => {
  await axios.delete('/affiliates/delete', { id })
  await dispatch(getData(getState().affiliates.params))
  await dispatch(getAllData())
  return id
})

// export const resendAffiliateInvite = createAsyncThunk('appAffiliates/resendAffiliateInvite', async (affiliate, { dispatch }) => {
//   const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate_fundraisers/resend_invite`, affiliate)
//   await dispatch(getDivisionAffiliate({ id: affiliate.affiliate, divisionId: affiliate.divisionId }))
//   return response?.data
// })

// export const removeOrganizationAffiliate = createAsyncThunk('appAffiliates/removeOrganizationAffiliate', async (affiliateData, { dispatch }) => {
//   const { affiliate, orgAffiliateId } = affiliateData
//   const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_affiliate/${orgAffiliateId}`)
//   await dispatch(getAffiliate(affiliate))
//   return response?.data
// })

export const addOrganizationAffiliates = createAsyncThunk('appAffiliates/addOrganizationAffiliates', async (orgData, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/organization_affiliate/bulkadd`, orgData)
  await dispatch(getAffiliate(orgData.affiliate))
  return response?.data
})

export const uploadAffiliateProfileImage = (formData, id) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/affiliate/${id}/avatar`, formData)
}

export const appAffiliatesSlice = createSlice({
  name: 'appAffiliates',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    conversions: [],
    programs: [],
    selectedUser: null,
    selectedAffiliate: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
      })
      .addCase(getAffiliateConversions.fulfilled, (state, action) => {
        state.conversions = action.payload.data
      })
      .addCase(getAffiliatePrograms.fulfilled, (state, action) => {
        state.programs = action.payload.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAffiliate.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appAffiliatesSlice.reducer
