import mock from './mock'

import './jwt'
import './pages/faq'

import './orgs/organizations'
import './orgs/leagues'
import './orgs/teams'
import './orgs/divisions'
import './fundraisers/fundraiser'
import './campaigns/campaign'
import './donations/donation'

import './apps/userList'
import './apps/todo'
import './pages/profile'
import './pages/blog-data'
import './tables/datatables'
import './pages/pricing-data'
import './navbar/navbarSearch'
import './pages/knowledge-base'
import './apps/permissionsList'
import './cards/card-analytics'
import './cards/card-statistics'
import './pages/account-settings'
import './autoComplete/autoComplete'

mock.onAny().passThrough()
