// ** React Imports
import BandAuthorize from '../../views/integrations/band/authorize'

const BandRoutes = [
  {
    element: <BandAuthorize />,
    path: '/band/authorize',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  }
]

export default BandRoutes
