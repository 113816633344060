// ** React Imports
import { lazy } from 'react'

const OnboardingsList = lazy(() => import('../../views/onboarding/list'))

const OnboardingRoutes = [
  {
    element: <OnboardingsList />,
    path: '/onboarding/list',
    meta: {
      action: 'read',
      resource: 'onboarding'
    }
  }
]

export default OnboardingRoutes
